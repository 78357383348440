body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.patient-priority-background {
  background: #eaf2fb;
}
.patient-priority-background:nth-of-type(odd) {
  background: #eaf2fb80;
}

.formik-flex {
  display: flex;
  flex-direction: column;
}

.gm-style-iw-t {
  display: none;
}

.ontario-health-logo {
  height: 150px;
  width: auto;
}

.search__icon-go-btn,
.search__icon-locate-btn {
  height: auto;
  width: 1em;
}

.search__input-postal-code {
  width: 100%;
}
.search__header {
  position: relative;
  z-index: 1;
  font-weight: 600;
}

.search__header::after {
  content: '';
  position: absolute;
  height: 0.1em;
  background: #00b2e3;
  width: 30%;
  left: 0;
  top: 90%;
  z-index: -1;
}
